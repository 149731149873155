<template>
  <nuxt-link
    :to="countyLink"
    class="service-card"
    :class="{
      'disabled': Boolean(messageDisabledNumber)
    }"
  >
    <div class="country-card__image">
      <nuxt-img
        :src="String(serviceImageId)"
        :alt="String(serviceName)"
        :title="String(serviceName)"
        width="108"
        height="108"
        fit="cover"
        loading="lazy"
      />
    </div>
    <div class="country-card__center">
      <div class="country-card__name">
        {{ serviceName }}
      </div>
      <div class="country-card__country">
        {{ countyName }}
      </div>
    </div>
    <div class="country-card__button">
      <div
        class="btn btn-primary"
        @click="buyNumber"
      >
        <span class="--text">{{$t('common.buy')}}</span>
        <span class="--hover">
          {{ priceFormat }}
        </span>
      </div>
    </div>

    <template v-if="messageDisabledNumber">
      <ServiceDisabledNumber
        :message="messageDisabledNumber"
      />
    </template>
  </nuxt-link>
</template>

<script lang="ts" setup>
import type { ComputedRef } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import { getNumberBitDepth } from '~/utils/numberFormat';

const ServiceDisabledNumber = defineAsyncComponent(() => import('~/components/common/ServiceDisabledNumber.vue'));

interface IProps {
  item: any,
  price: string,
}
const props = withDefaults(defineProps<IProps>(), {
  item: () => {
    return {}
  },
  price: "",
});
const emits = defineEmits(['buy']);
const localePath = useLocalePath();
const $i18n = useI18n();

const serviceName: ComputedRef<String> = computed(() => {
  return props?.item?.service?.name || ""
});
const serviceImageId: ComputedRef<String> = computed(() => {
  return String(props?.item?.service?.icon_id)
});

const countyName: ComputedRef<String> = computed(() => {
  return props?.item?.country?.name || ""
});
const countyLink: ComputedRef<RouteLocationRaw | undefined> = computed(() => {
  return localePath(['/', props?.item?.country?.slug].join(''))
});

const priceFormat: ComputedRef<String> = computed(() => {
  let _price = getNumberBitDepth(Number.parseFloat(props.price || '0'), 'auto');
  return [String(_price), '₽'].join('')
});

const messageDisabledNumber: ComputedRef<string> = computed(() => {
  if (String('props.item?.country?.external_id') === '0') {
    return $i18n.t('country.error_message_russian_numbers_long')
  }
  return ""
});

const buyNumber = async (event: any) => {
  event.preventDefault();
  event.stopPropagation();

  emits('buy', props.item);
};
</script>

<style scoped lang="scss">
.service-card {
  background: hsla(var(--white)/1);
  border-radius: 14px;
  cursor: pointer;
  min-height: 56px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0px 2px 4px 0px hsla(0, 0%, 0%, 0.07);

  &:not(.disabled):hover {
    background: hsla(var(--primary-bg)/0.2);
    color: hsla(var(--primary-bg)/1);

    .country-card__button .--text {
      opacity: 0;
    }
    .country-card__button .--hover {
      opacity: 1;
    }
  }
  &.loading {
    position: relative;
    overflow: hidden;
    pointer-events: none;
  }
}
.country-card__image {
  width: 44px;
  height: 44px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
.country-card__center {
  display: flex;
  overflow: hidden;
  flex: 1;
}
.country-card__name {
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: hsla(var(--base-tx)/1);
}
.country-card__country {
  display: flex;
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  color: hsla(var(--base-tx)/1);

  &:before {
    content: "/";
    font-weight: 400;
    color: hsla(var(--light-gray-tx)/0.8);
    margin-right: 6px;
  }
}
.country-card__button {
  margin-left: auto;

  .btn {
    padding: 4px 12px;
    border-radius: 6px;
    position: relative;
    min-width: 80px;

    font-size: 15px;
    line-height: 1.2;
    font-weight: 500;
    text-align: center;
  }

  .--text {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
  }
  .--hover {
    opacity: 0;
  }
}

@media (max-width: 1359px) {
  .service-card {
    min-height: 50px;
  }
  .country-card__image {
    width: 40px;
    height: 40px;
  }
}
</style>
