<template>
  <div class="country-service-v2">
    <div class="container">
      <div class="country-service-v2__content">
        <h2 v-if="title" v-html="title" class="country-service-v2__title"/>
        <div class="country-service-v2__items">
          <ServiceCard
            v-for="(item) in services"
            :key="`service-card-${ item?.country?.external_id }-${ item?.service?.external_id }`"

            :item="item"
            :price="servicesPrice[`${ item?.country?.external_id }_${ item?.service?.external_id }`] || ''"
            @buy="buyNumber"
          />
        </div>
        <div v-if="isVisibleMore" class="country-service-v2__button">
          <button
            class="btn btn-primary btn-outline"
            @click="changeIsMore"
          >{{ $t(isMore ? 'common.collapse' : 'common.show_more') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import type { ComputedRef } from 'vue';
import ServiceCard from '~/components/cms/country-service-block/ServiceCardV2.vue';
import { useBuyRentNumberStore } from '~/store/buyRentNumber';

const props = defineProps<IBlockDefaultProps>();
const {
  $api
} = useNuxtApp();
const {
  actionBuyNumber
} = useBuyRentNumberStore();

const title: ComputedRef<string> = computed(() => {
  return props?.values?.title;
});

const services: ComputedRef<any[]> = computed(() => {
  const _items = [...(props?.extras?.items || [])];
  if (!isMore.value) {
    return _items.splice(0, 6);
  }
  return _items;
});
const servicesPrice: Ref<any> = ref({});
const getServices = async () => {

  let searchAction: any = [
    `wholesale=${props?.values?.is_opt}`
  ];
  (props?.extras?.items || []).map((item: any, index: number) => {
    searchAction.push(`filter[${index}][country_external_id]=${item?.country?.external_id}`);
    searchAction.push(`filter[${index}][service_external_id]=${item?.service?.external_id}`);
  });
  searchAction = searchAction.join('&');

  const _services = await $api.agent.get(`/service/get-countries-services-prices?${searchAction}`).then((res: any) => {
    return res?._data || [];
  }).catch(() => {
    return [];
  });

  let _servicesPrice: any = {};
  _services.map((_item: any) => {
    _servicesPrice[`${_item?.country_external_id}_${_item?.service_external_id}`] = _item.price;
  });
  servicesPrice.value = _servicesPrice;
};
onMounted(async () => {
  await getServices();
});

const isMore: Ref<boolean> = ref(false);
const isVisibleMore: ComputedRef<boolean> = computed(() => {
  const _items = [...(props?.extras?.items || [])];
  return _items.length > 6;
});
const changeIsMore = () => {
  isMore.value = !isMore.value;
};

const buyNumber = async (item: any) => {
  await actionBuyNumber({
    countryId: item?.country?.external_id,
    serviceId: item?.service?.external_id
  });
};
</script>

<style lang="scss" scoped>
.country-service-v2 {
  margin: 56px 0;
  box-sizing: border-box;
}
.country-service-v2__content {
  padding: 26px;
  box-sizing: border-box;
  border-radius: 32px;
  background: hsla(var(--main-section-bg)/1);
  box-shadow: 0px 8px 16px 0px hsla(var(--black)/0.05);
  border: 1px solid hsla(var(--black)/0.1);
}
.country-service-v2__title {
  margin-bottom: 24px;

  font-size: 36px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: -0.03em;
  color: hsla(var(--main-section-tx)/1);
  text-align: center;
}
.country-service-v2__items {
  display: flex;
  flex-wrap: wrap;
  margin-top: -12px;
  margin-left: -12px;

  & > * {
    width: calc(50% - 12px);
    margin-top: 12px;
    margin-left: 12px;
  }
}
.country-service-v2__button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

@media (max-width: 1199px) {
  .country-service-v2__items {
    flex-direction: column;
    & > * {
      width: calc(100% - 12px);
    }
  }
}
@media (max-width: 1023px) {
  .country-service-v2__content {
    padding: 20px 10px;
  }
  .country-service-v2__title {
    margin-bottom: 18px;
    font-size: 24px;
  }
  .country-service-v2__items {
    flex-direction: row;
    margin-top: -6px;
    margin-left: -6px;
    & > * {
      width: calc(50% - 6px);
      margin-top: 6px;
      margin-left: 6px;
    }
  }
}
@media (max-width: 859px) {
  .country-service-v2__content {
    border-radius: 24px;
  }
  .country-service-v2__items {
    flex-direction: column;
    & > * {
      width: calc(100% - 6px);
    }
  }
}
</style>
